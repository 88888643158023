import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo/SEO';
import Img from 'gatsby-image';
import styles from './ServiceDetailed.module.scss';
import Layout from '../../components/layout/Layout';

export const query = graphql`
  query {
    rig: file(relativePath: { eq: "other/rig.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const TransformerPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO
        title={
          'Other services. We have worked with many challenges, maybe yours is one of the more special. Read about which other services we can provide.'
        }
      />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-48 mb-48">
          <div>
            <h1 className={styles.welcomeText}>Other services</h1>
            <p className={styles.descriptionText}>
              Other services include assessment of safety related to Li-ion battery installations,
              evaluating flammability and toxicity of electrolytes expelled from batteries during
              thermal run-away, secondary transformer explosions due to electric arcs, or dust
              explosions.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.rig.childImageSharp.fluid} alt="Hydrogen" />
            </figure>
          </div>
        </div>
        <div className={`grid_vertical_padding ${styles.articleTitle}`}>Published works:</div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/337199762_Modelling_the_consequences_from_water_explosions_in_molten_metal"
                target="_blank"
                rel="noopener noreferrer"
                title="Modelling the consequences from water
                explosions in molten metal."
              >
                Hansen, O. R., Kristiansen, A. (2019). Modelling the consequences from water
                explosions in molten metal.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1205/psep.04237"
                target="_blank"
                rel="noopener noreferrer"
                title="Simulating Dust Explosions with the First Version of DESC."
              >
                Skjold, T., Arntzen, B. J., Hansen, O. R., Taraldset, O. J., Storvik, I. E.,
                Eckhoff, R. K. (2005). Simulating Dust Explosions with the First Version of DESC.
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}>
          <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/259078926_Dust_explosion_venting_and_suppression_of_conventional_spray_dryers"
                target="_blank"
                rel="noopener noreferrer"
                title="Dust explosion venting and suppression of
                conventional spray dryers."
              >
                Siwek, R., Wingerden, K., Hansen, O. R., Sutter, G., Kubainsky, C., Schwartzback,
                C., Giger, G., Meili, R. (2004). Dust explosion venting and suppression of
                conventional spray dryers
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1051/jp4:20020307"
                target="_blank"
                rel="noopener noreferrer"
                title="Suppression of secondary explosions in
                transformer rooms."
              >
                Hansen, O. R., Wiik, A., Wilkins, B. (2002). Suppression of secondary explosions in
                transformer rooms.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title="ResearchGate"
              >
                More at ResearchGate
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TransformerPage;
